let styles = {
  title: {
    fontWeight: 700,
    fontSize: 18,
    color: 'var(--dark-blue-title)',
    lineHeight: '24px'
  },
  subtitle: {
    fontWeight: 400,
    fontSize: 14,
    lineHeight: '20px'
  },
  groupTitle: {
    fontWeight: 600,
    fontSize: 14,
    color: 'var(--bg-dark-blue)',
    lineHeight: '24px'
  },
  bgAnotherOption: {
    backgroundColor: 'var(--secondary-blue-0)',
    padding: '9px 9px 1px 9px',
    borderRadius: '6px 6px 0px 0px'
  },
  anotherOptionsContainer: {
    backgroundColor: 'var(--secondary-blue-0)',
    padding: '0px 9px 9px 1px',
    borderRadius: '0px 0px 6px 6px',
  }
}
export default styles;