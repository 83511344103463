/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import SignatureCanvas from 'react-signature-canvas'
import TitleAndImage from "../../components/molecules/title-and-image";
//  Assets
import arrow_back from "../../assets/icons/arrow_back.svg";
import check_grey_circle from "../../assets/icons/check_grey_circle_landscape.svg";
import arrow_grey_circle from "../../assets/icons/arrow_grey_circle_landscape.svg";
import check_orange_circle from "../../assets/icons/check_orange_circle_landscape.svg";
import arrow_blue_circle from "../../assets/icons/arrow_blue_circle_landscape.svg";
// Redux
import { addContractSignatureData } from "../../redux/slices/contractSignatureReducer";
// Utils
import listText from "../../utils/dictionaries/dictionary-customer-signature-action";
import { rotate } from "../../utils/utils";

const CustomerSignatureAction = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [disableButtons, setDisableButtons] = useState(true);
  const padRef = useRef<any>(null);

  const clearPad = () => {
    padRef.current?.clear();
    setDisableButtons(true);
  };

  useEffect(() => {
    if (padRef.current) {
      const signatureCanvas = padRef.current;
  
      signatureCanvas._canvas.onmouseup = () => {
        const isEmpty = signatureCanvas.toDataURL();
        setDisableButtons(!isEmpty);
      };

      signatureCanvas._canvas.ontouchend = () => {
        const isEmpty = signatureCanvas.toDataURL();
        setDisableButtons(!isEmpty);
      };
  
      return () => {
        if (padRef.current) {
          const signatureCanvas = padRef.current;
          signatureCanvas._canvas.onmouseup = null;
          signatureCanvas._canvas.ontouchend = null;
        }
      };
    }
  }, []);
  

  const saveTrimImage = () => {
    rotate(
      padRef.current?.getTrimmedCanvas().toDataURL("image/png"),
      270,
      function (resultBase64: any) {
        if (resultBase64) {
          dispatch(
            addContractSignatureData({
              signatureImageBase64: resultBase64,
            }),
          );
          navigate("/CustomerSignatureShow");
        }
      },
    );
  };

  const enabledButtons = () => {
    return (
      <div className="ps-3">
        <div className="py-3">
          <img src={`${check_orange_circle}`} onClick={() => saveTrimImage()} />
        </div>
        <div className="">
          <img src={`${arrow_blue_circle}`} onClick={() => clearPad()} />
        </div>
      </div>
    );
  };

  const disabledButtons = () => {
    return (
      <div className="ps-3">
        <div className="py-3">
          <img src={`${check_grey_circle}`} />
        </div>
        <div>
          <img src={`${arrow_grey_circle}`} />
        </div>
      </div>
    );
  };

  return (
    <>
      <div className="content-container">
        <div className="rounded-bottom-3 p-1" style={{ backgroundColor: "#00376F" }}>
          <TitleAndImage
            srcImage={arrow_back}
            titleText={listText.text_1.mainText}
            displayLeftImageTitle={""}
            classTitle={`fw-bold fs-5 ms-2`}
            styleTitle={{ color: "white" }}
            onClickGoBackIcon={() => navigate("/ContractCondition")}
            divClass={"mt-3 mb-3"}
          />
        </div>
        <div className="container mt-3">
          <div className="row">
            <div
              className="col-2"
              style={{ display: "flex", justifyContent: "center", alignItems: "center" }}
            >
              {disableButtons ? disabledButtons() : enabledButtons()}
            </div>
            <div className="col-9">
              <SignatureCanvas
                ref={padRef}
                canvasProps={{ className: "signature-canvas" }}
              />
            </div>
            <div
              className="col-1"
              style={{ display: "flex", justifyContent: "center", alignItems: "center" }}
            >
              <p
                style={{
                  writingMode: "vertical-rl",
                  textOrientation: "mixed",
                  color: "#AFAFAF",
                  fontSize: 18,
                  fontWeight: "500",
                }}
                className="pe-4"
              >
                {listText.text_5.mainText}
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CustomerSignatureAction;
