import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";

// Components
import { RadioToggleButton } from "../../components/molecules/radio-toggle-buttons";
import { Footer } from "../../components/templates";
import Loader from "../../components/atoms/loader";
import Checkbox from "../../components/molecules/checkbox";
import Input from "../../components/molecules/inputV2";
import Paragraph from "../../components/atoms/paragraph";
import Top from "../../components/templates/topV2";

// Utils
import vulnerabilityQuestionnaireDictionary from "../../utils/dictionaries/dictionary-vulnerability-questionnaire";

// Schema
import validationSchema from "./schema";

// Redux
import { onVulnerabilityQuestionnaire } from "../../redux/slices/clientInformation";
import { store } from "../../redux/store";

// Hooks
import { useDinamicSurvey } from "./hooks/useDinamicSurvey";
import { useFollowLater } from "../../hooks/useFollowLater";
import { useRedux } from "../../hooks/useRedux";

// Services
import UtilsCommonsService from "../../services/utilsCommons.service";
import { postVulnerabilityInformation } from "../../services/api/apiPersonalData";

// Styles
import styles from "./styles";

// Interfaces
import { IVulnerabilityQuestionnaireResp } from "../../interfaces/api/IUtilsCommons";
import MessagesReactModal from "../../components/templates/messages-react-modal";
import listText from "../../utils/dictionaries/dictionary-information-requirements";

// Assets
import hexagonExclamationThin from "../../assets/icons/hexagon-exclamation-thin.svg";
import ic_xclose from "../../assets/icons/ic_xclose.svg";

const VulnerabilityQuestionnaire = () => {
  const navigate = useNavigate();
  const state = store.getState();
  const utilsService = new UtilsCommonsService();
  const [vulnerabilityQuestionnaireData, setVulnerabilityQuestionnaireData] = useState<IVulnerabilityQuestionnaireResp[]>([]);
  const [selectedCheckboxes, setSelectedCheckboxes] = useState<{ [key: string]: boolean }>({});
  const [selectedValue, setSelectedValue] = useState<string>("No");
  const [isLoadingRedirect, setLoadingRedirect] = useState(false);
  const { onClickContinue } = useFollowLater();
  const { dataDinamicSurvey = [], errorDinamicSurvey, isLoadingDinamicSurvey } = useDinamicSurvey();
  const { data: { clientInformation: { vulnerabilityQuestionnaire }, clientPersist: { uuid } }, dispatch } = useRedux();
  const [modalShow, setModalShow] = useState(false);
  const anotherOptionCondition = selectedCheckboxes && selectedCheckboxes[vulnerabilityQuestionnaireData[1]?.answers[3]?.description]
  const prevSelectedValueRef = useRef(selectedValue); // Guardar el valor previo de selectedValue

  const formik = useFormik({
    initialValues: {
      surve: vulnerabilityQuestionnaire.surve?.length ? vulnerabilityQuestionnaire.surve : dataDinamicSurvey
    },
    validationSchema: validationSchema,
    onSubmit: (values) => console.log(values),
    enableReinitialize: true
  });

  const getVulnerabilityQuestionnaireData = async () => {
    setLoadingRedirect(true);
    try {
      const response = await utilsService.getVulnerabilityQuestionnaire({ processId: uuid });
      if (Array.isArray(response) && response.length > 1) {
        setSelectedValue(response[0]?.answers[1]?.description || "No");
        setVulnerabilityQuestionnaireData(response);
      }
    } catch (error) {
      console.error("Error fetching Vulnerability Questionnaire:", error);
      setModalShow(true)
    } finally {
      setLoadingRedirect(false);
    }
  };

  /**
   * Manages the change of checkboxes status in a form managed with Formik.
   * This function updates the checkboxes status visually (through `selectedCheckboxes`) and in Formik. 
   * When the checkbox 'Otro tipo de grupos' is deselected, it unchecks all related answers. 
   * in the “surve[2].answers” section and updates both the Formik status and the user interface.
   * 
   * @param {string} value - The value of the checkbox that has been selected or deselected.
   * 
   *  @returns {object} - Returns the updated state of the checkboxes (`selectedCheckboxes`), 
   * which is an object where each key is the checkbox description and the value is `true` or `false`.
   */
  const handleCheckboxChange = (value: string) => {
    setSelectedCheckboxes((prevSelected) => {
      // Visual status of checkboxes is updated
      const updatedSelected = { ...prevSelected, [value]: !prevSelected[value] };

      // If 'Otro tipo de grupos' is unchecked
      if (value === "Otro tipo de grupos" && !updatedSelected[value]) {

        //  Updated 'selection' in Formik correctly for the specific checkbox
        formik.setFieldValue('surve[1].answers', formik.values.surve[1].answers.map((answer, index) => {
          if (index === 3) {
            // Make sure that only the 'selection' is updated and the rest of the object remains the same.
            return { ...answer, selection: false };
          }
          return answer;
        }));

        // Update all the answers in 'surve[2].answers' to 'selection: false'.
        formik.setFieldValue(
          "surve[2].answers",
          formik.values?.surve[2]?.answers?.map((answer) => {
            return { ...answer, selection: false };
          })
        );

        // Update the visual status of all the checkboxes.
        const updatedVisualSelected = { ...updatedSelected };
        vulnerabilityQuestionnaireData[2]?.answers.forEach((answer) => {
          updatedVisualSelected[answer.description] = false;
        });

        // Return the updated visual state
        return updatedVisualSelected;
      }

      // If it is not 'Otro tipo de grupos', we only update that specific checkbox
      const surveyIndices = vulnerabilityQuestionnaireData?.map((_, index) => index) || [];
      surveyIndices.forEach((index) => {
        const surveyKey = `surve[${index}].answers`;

        // Update the 'selection' value in Formik for the selected checkbox
        formik.setFieldValue(
          surveyKey,
          formik.values?.surve[index]?.answers?.map((item) => {
            if (item.description === value) {
              return { ...item, selection: updatedSelected[value] };
            }
            return item;
          })
        );
      });

      // Return the updated visual state
      return updatedSelected;
    });
  };


  const btnDisabled = () => {
    /**
     * isAllUnselected: Checks if the selectedValue is 'Si' and all checkboxes are unchecked.
     * isSurveyEmpty: Checks if there are no questions in surve, that is, if the questionnaire is empty.
     * If selectedValue is 'No' and has changed, all answers in surve[2].answers 
     * are deselected and the value of prevSelectedValueRef.current is updated.
     * If the surve[1].answers[3].selection field is true, a condition is enabled 
     * to check if any answer in surve[2] has selection: true. If it does, 
     * the condition is disabled.     * 
    */
    const isAllUnselected = selectedValue === "Si" && Object.values(selectedCheckboxes).every(value => !value);
    const isSurveyEmpty = formik.values?.surve?.length === 0;
    let condition: boolean = false;
    const selectionTrueSurve2 = formik.values.surve[2]?.answers.some(item => item.selection === true)

    if (selectedValue === "No" && prevSelectedValueRef.current !== selectedValue) {
      formik.setFieldValue('surve[2].answers', formik.values.surve[2].answers.map((answer, index) => {
        return { ...answer, selection: false };
      }));
      prevSelectedValueRef.current = selectedValue;
    }

    if (formik.values.surve[1]?.answers[3]?.selection) {
      condition = true;

      if (selectionTrueSurve2) {
        condition = false;
      }
    }

    return (
      isAllUnselected ||
      isSurveyEmpty ||
      modalShow ||
      condition
    );
  };

  const submit = async () => {
    setLoadingRedirect(true);
    try {
      const response = await postVulnerabilityInformation(uuid, formik.values);
      if (response) {
        dispatch(onVulnerabilityQuestionnaire({ ...formik.values }));
        onClickContinue({
          data: { vulnerabilityQuestionnaire: formik.values },
          processId: uuid,
          resource: "VulnerabilityQuestionnaire",
          step: "CONTINUE",
        });
        navigate("/BeneficiariesClient");
      }
    } catch (error) {
      console.error("Error submitting Vulnerability Questionnaire:", error);
      setModalShow(true)
    } finally {
      setLoadingRedirect(false);
    }
  };

  const followLaterAction = () => {
    navigate("/FollowLater", {
      state: {
        resource: "VulnerabilityQuestionnaire",
        processId: uuid,
        step: "CONTINUE_AFTER",
        data: {
          confirmData: { ...state.client.confirmData },
          dataPersist: { ...state.client.dataPersist },
          clientInformation: { ...state.client.clientInformation }
        },
      },
    });
  };

  useEffect(() => {
    getVulnerabilityQuestionnaireData();
    setSelectedCheckboxes({});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (formik.values.surve.length) {
      if (!formik.values.surve[0]?.answers[0]?.selection) {
        setSelectedCheckboxes({});
        formik.setFieldValue("surve[0].answers[1].selection", true);
      } else {
        formik.setFieldValue("surve[0].answers[1].selection", false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values.surve]);

  const functionProcessModal = () => {
    setModalShow(false);
    window.location.reload();
  };


  if (isLoadingRedirect || isLoadingDinamicSurvey) return <Loader />;

  return (
    <>
      <div className="content-container">
        <Top
          titleHeader={vulnerabilityQuestionnaireDictionary.headerTitle}
          subTitle={vulnerabilityQuestionnaireDictionary.navigationTitle}
          displayLeftImageTitle="d-block"
          displayProgressBar="d-block"
          classHeader="mt-2"
          classProgressBar="mt-3"
          valueProgressBar="65%"
          onClickGoBackIcon={() => navigate("/UseAccountManagement")}
        />

        <div className="p-3">
          <Paragraph
            text={vulnerabilityQuestionnaireDictionary.title}
            styleParagraph={styles.title}
          />
          <Paragraph
            text={vulnerabilityQuestionnaireData[1]?.description}
            className="fs-6"
          />

          {!modalShow ?
            <>
              <div className="d-flex pb-3">
                <div className="toggle-button-group d-flex">
                  <RadioToggleButton
                    id="vulnerability"
                    text={formik.values.surve[0]?.answers[0]?.description}
                    value="Si"
                    type="radio"
                    name="vulnerability"
                    onChange={() => setSelectedValue("Si")}
                    onClick={() => {
                      formik.setFieldValue("surve[0].answers[0].selection", true);
                      formik.setFieldValue("surve[0].answers[1].selection", false);
                      setSelectedValue("Si")
                    }}
                    isSelected={selectedValue === "Si"}
                    className="radioToggleButton me-3"
                    displayImg="d-none"
                    labelStyle={{ paddingRight: "24px", paddingLeft: "24px" }}
                  />
                  <RadioToggleButton
                    id="vulnerability"
                    text={formik.values.surve[0]?.answers[1]?.description}
                    value="No"
                    type="radio"
                    name="vulnerability"
                    isSelected={selectedValue === "No"}
                    className="radioToggleButton"
                    displayImg="d-none"
                    labelStyle={{ paddingRight: "24px", paddingLeft: "24px" }}
                    onChange={() => { setSelectedValue("No"); setSelectedCheckboxes({}); }}
                    onClick={() => {
                      formik.setFieldValue("surve[0].answers[0].selection", false);
                      formik.setFieldValue("surve[0].answers[1].selection", true);
                      setSelectedValue("No");
                      setSelectedCheckboxes({});
                      formik.setFieldValue("surve", vulnerabilityQuestionnaire.surve?.length ? vulnerabilityQuestionnaire.surve : dataDinamicSurvey);
                    }}
                  />
                </div>
              </div>

              {selectedValue === "Si" && (
                <div className="py-3">
                  <Paragraph text={vulnerabilityQuestionnaireDictionary.group.title} styleParagraph={styles.groupTitle} />

                  {vulnerabilityQuestionnaireData[1]?.answers?.map((item, index: number) => (
                    <div key={index}>

                      <div style={anotherOptionCondition && index === 3 ? styles.bgAnotherOption : undefined}>
                        <Checkbox
                          text={item.description}
                          htmlFor={`flexCheckDefault-${index}`}
                          classNameLabel={"fs-7 ms-2"}
                          classCheckbox={"mb-4 d-flex"}
                          checked={!!selectedCheckboxes[item.description]}
                          onChange={() => handleCheckboxChange(item.description)}
                        />
                      </div>


                      {anotherOptionCondition && index === 3 && (
                        <div className="px-4" style={styles.anotherOptionsContainer}>
                          <Paragraph text={vulnerabilityQuestionnaireDictionary.group.anotherOptions} styleParagraph={styles.groupTitle} />

                          {vulnerabilityQuestionnaireData[2]?.answers?.map((item, index2: number) =>
                            <Checkbox
                              key={index2}
                              text={item.description}
                              htmlFor={`flexCheckDefault-${index2}`}
                              classNameLabel={"fs-7 ms-2"}
                              classCheckbox={"mb-4 d-flex"}
                              checked={!!selectedCheckboxes[item.description]}
                              onChange={() => handleCheckboxChange(item.description)}
                            />
                          )}
                        </div>
                      )}
                    </div>
                  ))}
                </div>
              )}
            </>
            : null
          }
        </div>
      </div>

      <Footer
        activeSpinner={false}
        disabledButton={btnDisabled()}
        onClickButtonPrimary={() => submit()}
        onClickButtonLink={() => followLaterAction()}
        labelButtonPrimary={vulnerabilityQuestionnaireDictionary.btn.submit}
        labelButtonSecundary={"Seguir más tarde"}
      />

      <MessagesReactModal
        show={modalShow}
        onHide={() => setModalShow(false)}
        textTitle={listText.text_24.mainText}
        classTitle={"fw-bold"}
        styleTitle={{ color: "#00254A", fontSize: "20px", fontWeight: "700" }}
        listButtonsModal={[
          {
            text: listText.text_25.mainText,
            style: {},
            divClass: "",
            buttonClass: "button-enabled w-100 place-content-center align-self-center mt-3",
            spinner: false,
            onClick: () => {
              functionProcessModal();
            },
            disabled: false,
          },
        ]}
        imageModal={hexagonExclamationThin}
        onClickClose={() => setModalShow(false)}
        imageOnlyClose={ic_xclose}
        classContent={"ml-3 mr-3 mb-3 text-center"}
        classImageModal={"text-center my-4"}
        listParagraphs={[
          {
            textParagraph: listText.text_26.mainText,
            classParagraph: "mt-2 mx-2",
            styleParagraph: { fontSize: 16, color: "#00254A", fontWeight: "450" },
          },
          {
            textParagraph: listText.text_27.mainText,
            classParagraph: "mt-2 mx-2",
            styleParagraph: { fontSize: 16, color: "#00254A", fontWeight: "450" },
          },
        ]}
      />
    </>
  );
};

export default VulnerabilityQuestionnaire;